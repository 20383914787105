<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-alert icon="mdi-alert-octagon" text type="error">
      ※基本は、クルーの申請により変更を行いますが、申請が難しい場合や時間がない場合に限り利用するようにしてください。また、各時間は管理者責任のもと設定してください。
    </v-alert>
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-col cols="12">
          <DatePicker
            v-model="date"
            prepend-inner-icon="mdi-calendar"
            outlined
            label="日付"
            :disabled="edit"
            :rules="[Rules.Required]"
        /></v-col>
        <v-col cols="12" class="dialog-padding">
          <v-autocomplete
            v-model="shozoku"
            :items="shozokus"
            outlined
            :rules="[Rules.Required]"
            label="所属"
            @input="shozokuSelect"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
          <v-autocomplete
            v-model="shift"
            :items="shifts"
            outlined
            label="シフト"
            @input="shiftSelect"
            item-text="name"
            item-value="code"
            chips
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
      <AttendanceFixTimeForm v-model="data" />
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="data.biko"
            label="備考"
            prepend-inner-icon="mdi-wallet"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <AttendanceFixRadioForm v-model="radio" />
    </v-form>
  </DialogBase>
</template>

<script>
import Vue from "vue";
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import AttendanceFix from "@/models/AttendanceFix";
import { COMMON_SHIFT_PATTERNS_REF as CommonShiftRefCode } from "@/defines";
import moment from "moment";
import AttendanceFixTimeForm from "@/components/form/attendance-fix/AttendanceFixTimeForm.vue";
import AttendanceFixRadioForm, {
  AttendanceFixRadio
} from "@/components/form/attendance-fix/AttendanceFixRadioForm.vue";

const Shozokus = [{ name: "", code: "" }];
const Shifts = [];

export default Vue.extend({
  name: "AttendanceFixDialog",
  components: { AttendanceFixTimeForm, AttendanceFixRadioForm },
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  props: {
    kkubun: {
      type: Number,
      default: 1,
      required: true
    }
  },
  computed: {
    Title() {
      const args = this.args;
      if (args) return "勤務実績追加・修正";
      const date = new Date(args.date);
      return args.name
        ? args.name
        : `${date.getMonth() + 1} 月 ${date.getDate()} 日`; //args.date;
    }
  },
  data() {
    return {
      date: "",
      data: {},
      shozokus: Shozokus,
      shozoku: {},
      shifts: Shifts,
      shift: {},
      toggle: [],
      edit: true,
      arrayEdit: false,
      recordShifts: {},
      radio: new AttendanceFixRadio(),
      forPaid: "",
      timeFormat: "HH:mm",
      minInterval: 15,
      affiliationflag: false
    };
  },
  methods: {
    shozokuSelect() {
      console.log("selected", this.shozoku);
      const shozoku = this.shozoku;
      const filtershifts = this.recordShifts.filter(
        e => e.refcode === shozoku || e.refcode === CommonShiftRefCode
      );
      this.shifts = filtershifts;
    },
    shiftSelect() {
      console.log("shiftSelect", this.shift, this.recordShifts);
      const filter = this.recordShifts.filter(
        e => e.code === String(this.shift)
      );
      if (filter.length > 0) {
        const set = filter[0];
        console.log("set", set);
        if (!this.edit) {
          this.data.start = set.option1;
          this.data.end = set.option2;
          this.data.breaktime = set.option3;
          this.data.opeTime = set.option5;
          //this.data.opeTime = set.option3;
        }
        if (set.refcode === "99999") {
          this.forPaid = set.code;
        } else {
          this.forPaid = "";
        }
      }
    },
    close(result) {
      this.resolve(result);
      this.$close();
    },
    async complete() {
      console.log("complete");

      if (!this.$refs.form.validate()) return;
      this.$loading();
      const data = { ...this.data };
      const args = this.args;
      Object.keys(args).forEach(key => {
        if (this.data[key]) args[key] = data[key];
      });

      data.date = new Date(this.date).getTime();
      if (this.$isEmpty(data.recordid)) {
        data.datestr = this.date;
        data.affiliation = this.shozoku;
      }
      data.shiftpatternid = this.shift ? this.shift : "";
      if (this.shift !== null && !this.$isEmpty(this.shift.code)) {
        data.shiftpatternid = this.shift.code ? this.shift.code : "";
      } else {
        data.shiftid = "";
      }
      if (data.shiftpatternid === "") {
        this.forPaid = "";
      }
      const setshiftid = [data.shiftid];
      const szk = [""];
      console.log("shozoku", this.shozoku);
      console.log("shozoku.code", this.shozoku.code);
      console.log("shiftpatternid", this.shift);
      if (this.shozoku.code == undefined) {
        szk[0] = this.shozoku;
      } else {
        szk[0] = this.shozoku.code;
      }
      if (data.affiliation != szk[0]) {
        setshiftid[0] = "";
      }
      console.log("forPaid", this.forPaid);

      const payLoad = new AttendanceFix({
        recordId: data.recordid,
        employeeId: data.employee,
        affiliationId: szk[0],
        targetShiftId: setshiftid[0],
        shiftpatternid: data.shiftpatternid,
        attendanceDate: data.datestr,
        startTime: data.start,
        endTime: data.end,
        movingDistance: data.distance,
        breaktime: data.breaktime,
        morningbreaktime: data.morningbreaktime,
        midnightbreaktime: data.midnightbreaktime,
        work: data.work,
        over: data.over,
        morning: data.morning,
        midnight: data.midnight,
        midnightover: data.midnightover,
        inover: data.inover,
        leaveTime: data.leaveTime,
        lateTime: data.lateTime,
        lateleave: this.selectLateLeave,
        hadmeal: data.hadmeal,
        workclassificationid: this.forPaid > 0 ? this.forPaid : this.radio.work,
        amount: data.amount,
        reserveAmount1: data.reserveAmount1,
        reserveAmount2: "",
        reserveid1: "0",
        nightShift: "0",
        careShu: "0",
        careKbn: "0",
        timeKbn: "0",
        nightGuard: "0",
        emergencyFlag: "0",
        midnightTel: "0",
        assistFlag: this.radio.assist,
        otherFlag: "0",
        absence: "0",
        overtimeflag: this.radio.overWork,
        biko: data.biko,
        opeTime: data.opeTime
      });

      try {
        let result;
        if (this.$isEmpty(data.recordid)) {
          result = await this.$post(this.Paths.attendanceFix, payLoad);
        } else {
          result = await this.$put(this.Paths.attendanceFix, payLoad);
        }

        if (result) {
          await this.$info("更新しました。", "勤務実績");
          console.log("complete end");
          this.$close(true);
        }
      } catch (e) {
        console.log("complete error", e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async getShozoku() {
      console.log("shozoku");
      try {
        const result = await this.$get(this.Paths.shozokuFilterAssist);
        if (result) {
          this.shozokus.length = 0;
          this.shozokus = result;
          if (this.edit || this.affiliationflag) {
            const shozokufirst = result.filter(
              e => e.code === this.data.affiliation
            );
            this.shozoku = shozokufirst[0];
          }
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getShiftPattern() {
      console.log("shift-pattern");

      try {
        const result = await this.$get(this.Paths.shiftpatternPulldown);
        if (result) {
          this.shifts.length = 0;
          this.recordShifts.length = 0;
          this.recordShifts = result;

          console.log("affiliation", this.data.affiliation);
          if ((this.edit || this.affiliationflag) && this.data.affiliation) {
            this.shifts = result.filter(
              e =>
                e.refcode === this.data.affiliation ||
                e.refcode === CommonShiftRefCode
            );

            if (this.data.shiftpatternid) {
              let idx = 0;
              let counter = 0;
              this.shifts.forEach(f => {
                if (f.code === this.data.shiftpatternid) idx = counter;
                counter += 1;
              });
              this.shift = this.shifts[idx];
            }
          }
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    setRadioValues() {
      // 出勤種別
      if (this.data.workclassificationid === "1") {
        this.radio.work = "1";
      } else {
        this.radio.work = "0";
      }

      if (this.data.workclassificationid) {
        if (parseInt(this.data.workclassificationid) > 90000) {
          this.forPaid = this.data.workclassificationid;
        } else {
          this.forPaid = "";
        }
      }

      // 応援・その他
      this.radio.assist = this.data.assistFlag;

      // 時間外フラグ
      this.radio.overWork = this.data.overtimeflag;

      console.log(this.data);
    }
  },
  async created() {
    console.log("created attendancefixdialog", this.args);
    const args = this.args;
    if (!this.$isEmpty(args)) {
      if (this.$isEmpty(args["recordid"])) {
        this.edit = false;
      } else {
        this.edit = true;
      }

      if (!Array.isArray(args)) {
        this.data = { ...args };
        console.log("data", this.data);

        if (!this.$isEmpty(args["affiliation"])) {
          this.affiliationflag = true;
        }
      } else {
        this.arrayEdit = true;
        const data = {};
        console.log("fix data", data, this.data);
        args.forEach(select => {
          Object.keys(select).forEach(key => {
            if (!data[key]) data[key] = select[key];
            else if (data[key] !== select[key]) data[key] = null;
          });
        });
        this.data = data;
        console.log("fixed data", data, this.data);
      }
      if (
        this.data.assistFlag !== "1" &&
        this.data.otherFlag !== "1" &&
        this.kkubun !== 4
      ) {
        this.data.amount = 0;
      }

      this.date = moment(this.data.datestr).format("YYYY-MM-DD");

      await this.getShozoku();
      await this.getShiftPattern();
      this.setRadioValues();
    }
  }
});
</script>

<style scoped>
.margin-row {
  margin-top: 0px;
}
.dialog-padding {
  padding-top: 0px;
}
</style>
